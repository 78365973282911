.cheapest_view_item {
  display: flex;
  align-items: center;
  margin-right: 10px;

  h5 {
    font-size: 1rem;
    margin-right: 5px;
  }
  button{
    display: flex;
    align-items: stretch;
    height: 100%;
    img{
      height: 20px;
      width: 20px;
    }

  }
  .edit_icon {
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
  }

  .delete-icon{
    height: 100%;
  }

  form {
    display: flex;

    input {
      border: none;
    }

    button {
      margin: 0;
      padding: 5px;
      border: none;
    }
  }
}