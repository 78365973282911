.shopping-list {
  .item-selected {
    background: lightgray;
  }

}

.shopping-item {
  display: flex;
  margin-bottom: 5px;
  width: 80vw;
  height: 250px;
  padding: 30px 10px 10px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border: 2px solid black;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .listItemTitle{
    position: absolute;
    font-weight: bold;
    top: 5px;
  }

  h2 {
    padding: 10px;
    font-size: inherit;
  }

  h3 {
    flex-shrink: 1;
    padding: 3px;
  }

  .price_part{
    text-align: center;
    flex-grow: 1;
    h3{
      width: auto;
    }
  }

  .remove_from_cart_btn {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
      font-size: 2rem;
      padding: 10px;
  }

  .main_product_img_wrapper {
    min-height: 0;
    height: 100%;

    max-width: 500px;
    flex-grow: 0;
    img {
      //width: 300px;
      height: 100%;
      width: 95%;
    }
  }

  .alternative_product_view > * {
    flex-basis: 100%;
  }

  .alternative_product_view {
    max-width: 500px;
    flex-basis: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      flex-shrink: 3;
      padding: 10px;
    }

    > * {
      flex-basis: 100%;
    }

    .main_product_img_wrapper {
      min-height: 0;

      img {
        //max-width: 250px;
        height: 100%;

      }
    }

    .child_product_images {
      min-height: 0;
      flex-shrink: 2;

      img {
        height: 100%;

        object-fit: contain;
      }
    }
  }

  .oldPrice {
    color: red;
    text-decoration: line-through;
    margin-left: 5px;
  }

  .discountPercent {
    margin-left: 5px;
  }

  .add-expense-dialog{
    .add-expense-dialog-btn{
      padding: 40px;
      background: none;
      border: none;
      outline: none;
      font-size: 60px;
    }
  }
}


@media screen and (max-width: 992px) {
  .shopping-item {
    height: 200px;
    width: 90vw;
    img {
      height: 100%;
      width: 100%;
      //max-width: 150px;
      object-fit: contain;
    }


    h2 {
      padding: 5px;
      font-size: 12px;
      white-space: pre-wrap;

    }

    h3 {
      width: fit-content;
      font-size: 12px;
      white-space: pre-wrap;
      flex-wrap: nowrap;
    }
  }


  .item-selected {
    background: lightgray;
  }

}


@media screen and (min-width: 0) {
  .shopping-item {
    .child_product_images {
      img {
        margin-left: 0;
      }
    }
  }
}


@media screen and (min-width: 600px) {
  .shopping-item {
    img {
      height: 100%;
      //max-width: 250px;
      object-fit: contain;
    }
.main_product_img_wrapper{
  flex:2;
}
    .child_product_images {
      img {
        margin-left: 5%;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .shopping-item {
    height: 125px;
    width: 90vw;
    padding: 0;

    button {
      font-size: 1rem;
      padding: 5px;
    }

    img {
      height: 100%;
      object-fit: contain;
    }

    .shopping-item .main_product_img_wrapper img {
      width: 400px;
      height: 100%;
    }
  }
}