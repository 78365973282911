.expenseRecordsTable {
  th, td {
    padding: 5px;
  }
}

@media screen and (max-width: 1000px) {
  .expenseRecordsTable {
    th, td, p, select {
      font-size: 0.65rem;
      padding: 3px;
    }

    input {
      font-size: 0.65rem;
      padding: 5px;
    }

    select {
      min-width: 50px;
    }

  }

}