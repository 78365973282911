.product {
  justify-content: space-between;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-width: 300px;
  //height: 500px;
  padding: 20px;
  margin-right: 5px;
  overflow: hidden;
  min-width: 300px;

  h2 {
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  h3 {
    white-space: pre-wrap;
  }


  .base_image {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .main_product_img_wrapper {
    height: 200px;
    position:relative;
  }


  .addToBaskedBtn {
    padding: 10px;
    font-weight: bold;
    margin-top: 5px;
  }

  .categoryBtn {
    align-self: center;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
    width: fit-content;
  }

  .alternative_product_view {
    .child_product_images {
      height: 100px;
      display: flex;
      justify-content: space-around;
      img {
        flex-basis: 100%;
        object-fit: contain;
      }
    }

    h2 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .oldPrice{
    color: red;
    text-decoration: line-through;
    margin-left: 5px;
  }

  .discountPercent{
    margin-left: 5px;
  }

  .shopMetaData{
    display: inline-block;
    h5{
      display: inline-block;
    }

    img{display: inline-block}
  }


}

.selected-product {
  background: #e7e5e5;
}