.cartBindingPart {
  text-align: start;
  margin-top: 10px;
  margin-bottom: 30px;

  p {
    text-align: start;
    padding: 5px 5px 5px 0;
  }

  .bindedDetails {
    display: flex;
    padding: 20px;
    gap: 10px;
    align-items: center;
  }
}