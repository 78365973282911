ol{
  padding: 0;
}
.tabs{
  margin-top: 5px;
}
.tab {
  &:hover{
    cursor: pointer;
  }
  padding-bottom: 4px;
  display: inline-block;
  margin-right: 20px;
  .tab-btn {
    background: none;
    border: none;
    text-transform: capitalize;
    padding: 10px 10px;
    font-size: 1rem;
    font-weight: 700;
    color: #607d8b;

    &:disabled{
      font-weight: bold;
      color: black;
    }
  }

  input[type='checkbox']{
    zoom: 2;
    position: relative;
    top: 4px;
  }
}

.active-tab {
  border-bottom: 3px solid #b0bec5;
  .tab-btn{
    color: #b0bec5;
  }
}
.categoryItemDeleteBtn{
  color: black;
  margin-right: 5px;
  //margin-left: 0;
  padding: 5px;
}

@media screen and (max-width: 992px) {
  ol{
    margin-top: 0;
  }
  .tab {
    margin: 0;
    text-align: center;
    .tab-btn {
      padding: 0;
    }
    span{
      padding-left: 5px;
    }
  }
}