.notFoundPage {
  padding: 50px;

  font-weight: bold;

  p {
    padding: 20px;
    font-size: 2.5rem;
  }

}