.tab-list {
  margin-top: 0;
  border: 3px solid black;
 padding: 5px;
}

@media screen and (max-width: 992px) {
  .tab-list {
    border: 2px solid black;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

