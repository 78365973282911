.price-note-wrapper {

  border: 2px solid black;


  .price-note {
    border: 2px solid black;

    margin: 10px;
    display: flex;
    justify-content: center;
    text-align: center;

    span {

      margin: 0;
      padding: 4px;
      flex: 1;
    }
  }
}

.record-expenses-form{
  margin: 30px;
  display: flex;
}