.SearchPart {
  margin-bottom: 20px;
  .search-container {
    background: gray;
    padding: 20px;
  }

  .inputContainer {
    padding-bottom: 2px;
    input {
      width: 45%;
      &:focus-visible {
        outline-color: #78909c;
      }
    }

    input, button {
      padding: 10px;
    }
  }

  .submit-btn{
    width: 50%;
    font-size: 2rem;
    letter-spacing: 10px;
  }
}

@media screen and (max-width: 992px) {
  .SearchPart {
    margin-bottom: 10px;
    .search-container {
      background: gray;
      padding: 10px;
    }
    .submit-btn{
      width: 95%;
      font-size: 2rem;
      letter-spacing: 10px;
    }
    .inputContainer {
      input {
        width: 80%;
      }
    }
  }


}