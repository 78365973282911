
.cheapest_view{
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  .button-plus{
    margin-left: 15px;
    margin-right: 15px;
    img{
      height: 20px;
      width: 20px;
    }
  }

}