.product-category {
  position: relative;
  padding: 10px 20px;
  text-align: start;
  border: 3px solid black;
  width: 70%;
  height: fit-content;
  margin: 10px auto;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  h2 {
    padding: 0;
    margin: 0 0 10px;
  }

  button {
    padding: 10px;
    background: none;
    color: black;
    outline: none;
    border: 3px solid black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 3px;
  }

  .selectedBtn {
    background: #78909c;
    color: white;
  }

  .categoryItemDeleteBtn{
    letter-spacing: 0px;
    &:hover{
      background-color: lightslategrey;
    }
  }
  .removeItemContainer{
    padding: 2px;
    border: 2px solid black;
    button{
      padding: 5px;
      border: none;
    }
  }

  .categoryItemAddBtn{
    position: absolute;
    transform: translateX(-115%);
    left: 0;
    &:hover{
      background-color: lightslategrey;
    }
  }
}

#itemAddSection{
  width: 70%;
  text-align: start;
  margin-top: 0.5rem;
}

@media screen and (max-width: 992px) {
  .product-category {
    text-align: start;
    border: 2px solid black;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;

    button {
      padding: 5px;
      border: 2px solid black;
      font-size: 0.7rem;
      letter-spacing: 1px;
    }



  }
}