.navbar{
  padding: 5px;
  background: lightgray;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  a, button{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: white;
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    display: flex;
    //margin-right: 20px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
  }


}


.btn-g-login {
  background: none;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-end;
  color: lightslategrey;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  text-transform: uppercase;

}

.btn-g-login img {
  align-self: center;
}



@media screen and (max-width: 900px) {
  .navbar{
    a, button{
      font-size: 1.3rem;
      padding: 2px;
    }
  }
}


@media screen and (max-width: 380px) {
  .navbar{
    a, button{
      font-size: 1.2rem;
      margin-right: 5px;
      padding: 1px;
    }
  }
}