.ShoppingListSection {
  padding: 30px;

  .shop-heading {
    font-size: 2rem;
    padding: 30px;
    text-transform: capitalize;
    letter-spacing: 5px;
    margin: 0;
  }

  h2:first-of-type {
    padding-top: 0;
  }


}

.noItemsFoundMessage {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  a {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    text-underline: none;
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid black;
    background: #78909c;
    border-radius: 5px;
    padding: 20px;
    font-weight: bold;
    color: white;
  }

}

@media screen and (max-width: 992px) {
  .ShoppingListSection {
    padding-left: 4px;
    padding-right: 4px;
  }
}


@media screen and (max-width: 380px) {
  .ShoppingListSection {
  }
}