
.searchOptions {
  //padding-top: 30px;
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bolder;
    font-size: 20px;
  }

  .categoriesSwitch{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    div{
      padding: 10px;
    }
    label{
      outline: none;
    }
  }

}

.searchOptionsSaveDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 2rem;
  }

  .btnSave {
    background-color: limegreen;
  }

  .btnCancel {
    background-color: lightgrey;
    margin-right: 10px;
  }
}

@media screen and (max-width: 992px) {
  .SearchPart {
    h2 {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

}
@media screen and (max-width: 992px) {
  .searchOptionsSaveDialog {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 10px;
      font-weight: bold;
      letter-spacing: 2px;
      font-size: 1.5rem;
    }

    }

  }