.MenuPage{
  padding-left: 5%;
  padding-right: 5%;
  .alterCategoriesPart{
    margin-top: 20px;
    margin-bottom: 20px;
      #search-form{
        display: flex;
      }

  }

  .categoryEditLabel{
    font-size: 0.7rem;
    font-weight: bold;
  }
h1{
  font-weight: bold;
  font-size: 2rem;
}
  h2{
    text-align: start;
    font-weight: bold
  }


  hr{
    height: 1px;
    background: black;
  }

  .submitTextInput{
    width: 30%;
    margin: auto;
  }


  @media screen and (max-width: 900px) {
    .submitTextInput{
      width: 90%;
    }
  }
}