.inputWithBtn {
  border: 2px solid black;
  width: fit-content;

  button {
    border: none;
    &:hover{background-color: lightslategrey;
    }
  }
  label{
    display: block;
  }

  input {
    border: none;
    outline: none;
    padding-left: 10px;
  }
}