.productSection {
  padding: 10px 50px;
  .productRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    flex-basis: 0;
    overflow: scroll;

  }

  .product-section-header {
    display: flex;
    background: lightgray;
    border-bottom: 3px solid black;

    h2 {
      text-align: start;
      padding-top: 10px;
      text-transform: capitalize;
      font-size: 2.5rem;
      letter-spacing: 5px;
      margin: 0;
    }


    .product-section-header-options {
      font-weight: bold;
      font-size: 1.5rem;
      user-select: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      input[type='checkbox'] {
        zoom: 2;
      }

      input {
        font-size: 1.5rem;
      }
    }

  }

  .category-search-form {
    display: flex;
    background: red;
    color: red;

    input {
      border: none;
      outline: none;
    }

    button {
      border: none;
      outline: none;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: bold;

      &:active {
        background: darkgray;
      }
    }
  }

}

.scroll-container {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
  cursor: grab;

}


@media screen and (max-width: 992px) {
  .productSection {
    padding: 0;

    .product-section-header {
      h2 {
        font-size: 1.5rem;
        letter-spacing: 5px;
        margin-left: 10px;
      }


      .product-section-header-options {
        font-size: 1rem;
        margin-left: 10px;

        input {
          font-size: 1rem;
        }
      }

      .category-search-form {
        display: flex;
        background: red;
        color: red;

        input {
          width: 100px;
          border: none;
          outline: none;
        }

        button {
          text-transform: uppercase;
          letter-spacing: normal;
          font-weight: bold;

          &:active {
            background: darkgray;
          }
        }
      }
    }

  }
}


@media screen and (max-width: 350px) {
  .productSection {
    padding: 0;

    .product-section-header {
      .product-section-header-options {
        text-align: start;
        margin-left: 10px;

        input[type=text] {
          //width: 50%;
        }

        label {
          flex-grow: 10;
          flex-basis: 0;
          flex-wrap: nowrap;
        }

        span {
          padding: 5px;
          font-size: 0.9rem;
        }

        input {
          font-size: 1rem;
        }

        .detailed-search-checkbox {
          flex-grow: 10;
          flex-basis: 0;
          flex-wrap: nowrap;
        }
      }

    }

  }
}


@media screen and (max-width: 600px) {
  .product-section-header {
    flex-direction: column;
  }
  .cheapest_view {
    padding: 5px 0 0 !important;
    justify-content: start;

    .button-plus-wrapper {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: start;

    }

    .cheapest_view_item {
      h5 {
        font-size: 0.85rem;
      }

      .edit_icon {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .product-section-header-options {
    margin-left: 3px !important;
  }
}